<script setup>
const props = defineProps({
  to: {
    type: String,
    default: "/",
  },
  target: String,
  rel: String,
  prefetch: Boolean,
  file: [Object, String],
  data: Object,
  ariaLabel: String,
});

const downloadURL = computed(() => {
  if (typeof props.file === "string" && props.file !== "") {
    return props.file;
  }
  return null;
});
</script>

<template>
  <div class="custom-link" v-if="data?.file || file?.url || downloadURL">
    <a class="link" :href="data?.file[0]?.file.url || file?.url || downloadURL" target="_blank"
      :title="data?.file[0]?.title || file?.title" :aria-label="ariaLabel || data?.file[0]?.title || file?.title">
      <slot name="label" />
    </a>
  </div>
  <div class="custom-link" v-else>
    <nuxt-link v-if="data?.page_link" v-bind="{ ...props }" class="link" :to="data?.page_link || to"
      :aria-label="ariaLabel || data?.title || data?.url || to">
      <slot name="label" />
    </nuxt-link>
    <nuxt-link v-else v-bind="{ ...props }" class="link" :to="data?.url || to" :external="true" target="_blank"
      :aria-label="ariaLabel || data?.title || data?.url || to
        ">
      <slot name="label" />
    </nuxt-link>
  </div>
</template>

<style lang="postcss" scoped>
.custom-link {
  cursor: pointer;
  text-decoration: none;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
}
</style>
